import { ViewerControllerProps } from '../../types/viewer';
import {
  IAppData,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';

export default ({ controllerConfig }: ViewerControllerProps) => {
  const config: IControllerConfig = controllerConfig.config;
  const appParams: IAppData = controllerConfig.appParams;

  // Here you can use wixCodeApi (corvid), API reference is here:
  // https://bo.wix.com/wix-docs/client/viewer-platform---ooi/reference/wixcode-apis
  // And Wix Selector ($w) to manipulate with elements on the page, API reference is here:
  // https://www.wix.com/corvid/reference/$w
  // controllerConfig has more useful data, for the details look inside types and here:
  // https://bo.wix.com/wix-docs/client/viewer-platform---ooi/articles/lifecycle
  const { $w, wixCodeApi, compId } = controllerConfig;
  const viewMode = wixCodeApi?.window?.viewMode ?? 'Preview';
  return {
    pageReady: () => {
      if ($w(`@webComponent`).length !== 0) {
        const stringified = JSON.stringify({
          data: config,
          viewMode,
          instance: appParams.instance,
          compId,
        });
        $w(`@webComponent`).setAttribute('wixConfig', stringified);
      }
    },
  };
};
