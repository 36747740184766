
  import {createControllersWithDescriptors, initAppForPageWrapper} from 'yoshi-flow-editor-runtime/build/esm/viewerScript.js';
  
  
            import controller0 from '/home/builduser/agent00/work/859cd99042ef5abe/web-component-wrapper/src/components/webComponent/viewer.controller.ts';

  
  var importedApp = {};

  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';

  var sentryConfig = null;

  var experimentsConfig = null;

  var translationsConfig = null;

  var defaultTranslations = null;

  var biLogger = null;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "web-component-wrapper",
    biConfig: null,
    appName: "Editor flow test platform app",
    appDefinitionId: "9d2b2137-16ca-4d36-a226-124d72602e1a",
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    optionalDeps: {
      
    },
  });

  export const createControllers = createControllersWithDescriptors({
     
  }, [{ method: controller0,
          widgetType: "STUDIO_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: null,
          defaultTranslations: defaultTranslations,
          biLogger: biLogger,
          biConfig: null,
          controllerFileName: "/home/builduser/agent00/work/859cd99042ef5abe/web-component-wrapper/src/components/webComponent/viewer.controller.ts",
          appName: "Editor flow test platform app",
          appDefinitionId: "9d2b2137-16ca-4d36-a226-124d72602e1a",
          projectName: "web-component-wrapper",
          componentName: "webComponent",
          id: "webComponent" }], "all");
